import React from 'react';
import { Typography, TypographyProps } from '@material-ui/core';
import useStyles from "./styles";
import cl from "classnames";
import {Color} from "./types";

type TitleProps = {
    className ?: string,
    sub ?: boolean,
    size ?: number,
    color ?: Color
} & Pick<TypographyProps, 'align'>

const Title: React.FC<TitleProps> = (props) => {

    const { children, align, className, sub = false, size, color = "light" } = props

    const classes = useStyles(color)()

    return (
        <Typography
            variant={sub ? "h2" : "h4"}
            component="div"
            align={align}
            style={size ? {fontSize : size} : {}}
            className={cl(classes.root,className)}
        >
            {children}
        </Typography>
    );
};

export default Title;