import React, {useCallback, useState} from "react";

type GoToLast = (id ?: string) => void

export type StepComponentType<T = {}> = React.FC<{
    next: (id ?: string) => void,
    prev: (id ?: string) => void,
    goToLast: GoToLast,
    initialId ?: string
} & T>

interface StepReturn<T> {
    Component: StepComponentType<T>,
    currentIndex: number
    next: () => void,
    prev: () => void,
    id: string,
    last: boolean
    goToLast: GoToLast
    initialId ?: string
}

interface Options {
    initialId ?: string
    initialIndex ?: number
}

const useStep = <T = {}, >(components: StepComponentType<T>[], options : Options = {}): StepReturn<T> => {

    const {initialId = "", initialIndex = 0} = options
    const [currentIndex, setCurrentIndex] = useState(initialIndex)
    const [id, setId] = useState(initialId)

    const Component = components[currentIndex]
    const last = currentIndex + 1 === components.length

    const goToLast : GoToLast = useCallback((id) => {
        setId(id || "")
        setCurrentIndex(components.length - 1)
    }, [components])

    const next = useCallback((id ?: string) => {
        if (last) {
            return
        }

        setId(id || "")
        setCurrentIndex(prev => ++prev)
    }, [last])

    const prev = useCallback((id ?: string) => {
        setCurrentIndex(prev => --prev)
    }, [])

    return {
        Component,
        currentIndex,
        next,
        prev,
        id,
        initialId,
        last,
        goToLast,
    }
}

export default useStep