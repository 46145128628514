import {useCallback} from "react";
import {Validation} from "v-for-validation";
import {Errors} from "v-for-validation/dist/types";

const flattenTarget = <T extends {[key : string] : any | {target : {value : string}}}>(data : T) => {
    return Object.keys(data).reduce((acc,field) => {

        let value = data[field]
        if(value?.target?.value){
            value =  value?.target?.value
        }
        // @ts-ignore
        acc[field] = value
        return acc
    }, {} as T)
}

/*const emptyFy = (data : any) => {
    return Object.keys(data).reduce((acc,field) => {
        acc[field] = []
        return acc
    }, {} as any)
}*/

const useResolver = <T>(validator : Validation<T> ) => useCallback(async ( data : T) => {

    const flattenedData = flattenTarget(data)
    try {
        await validator.validate(flattenedData)
        return {
            values : flattenedData as any,
            errors : {}
        }
    } catch (errors) {
        return {
            values : flattenedData as any,
            errors : errors as Errors<T>
        }
    }
}, [validator])

export default useResolver