import {MeetingStateData, NavigationScene} from "./types";

type Dispatch<A> = (value: A) => void
type SetStateAction<S> = S | ((prevState: S) => S)
export type MeetingStateSetter = Dispatch<SetStateAction<MeetingStateData>>

class MeetingState {
    private initialState: MeetingStateData = {
        currentArea: "mainarea",
        sharing : false,
        guided : true,
        areas: [
            {name: "Main World", scene: "mainarea" as NavigationScene, locked: true},
            {name: "Xfinity Store", scene: "store" as NavigationScene, locked: true},
            {name: "Apartment", scene: "apartment" as NavigationScene, locked: true},
            {name: "Single Family Home", scene: "home" as NavigationScene, locked: true}
        ]
    }

    private stateSetter: MeetingStateSetter = null as unknown as MeetingStateSetter

    constructor() {
        this.initial = this.initial.bind(this)
        this.set = this.set.bind(this)
        this.current = this.current.bind(this)
        this.setStateSetter = this.setStateSetter.bind(this)
        this.setSceneLock = this.setSceneLock.bind(this)
        this.getStoredState = this.getStoredState.bind(this)
        this.lockAreas = this.lockAreas.bind(this)
        this.unlockAreas = this.unlockAreas.bind(this)
        this.store = this.store.bind(this)
        this.remove = this.remove.bind(this)
    }

    initial() {
        return this.initialState
    }

    set<K extends keyof MeetingStateData>(data: Pick<MeetingStateData, K> | MeetingStateData) {
        this.stateSetter(prevState => ({
            ...prevState,
            ...data
        }))
    }

    current(fn: (prevState: MeetingStateData) => void) {
        this.stateSetter(prevState => {
            fn(prevState)
            return prevState
        })
        return this.initialState
    }

    setStateSetter(setter: MeetingStateSetter) {
        this.stateSetter = setter
    }

    setSceneLock(scene: NavigationScene, locked: boolean) {
        this.stateSetter(p => ({
            ...p,
            areas : p.areas.map(area => {
                if (area.scene === scene) {
                    return {
                        ...area,
                        locked
                    }
                }
                return area
            })
        }))
    }

    getStoredState(meetingCode: string): MeetingStateData | null {
        const state = localStorage.getItem("meetingState")
        if (!state) {
            return null
        }
        const parsedState = JSON.parse(state)

        if (parsedState[meetingCode]) {
            return parsedState[meetingCode]
        }

        return null
    }

    lockAreas(data : MeetingStateData) : MeetingStateData {
        return {
            ...data,
            areas: data.areas.map(area => ({...area, locked : true}))
        }
    }

    unlockAreas(data : MeetingStateData) : MeetingStateData {
        return {
            ...data,
            areas: data.areas.map(area => ({...area, locked : false}))
        }
    }

    store(meetingCode: string, meetingState: MeetingStateData) {
        localStorage.setItem("meetingState", JSON.stringify({
            [meetingCode]: meetingState
        }))
    }

    remove() {
        localStorage.removeItem("meetingState")
        return this
    }
}

export default MeetingState