import {AuthTypes} from "Commons";
import {Meeting, HotspotAnalyticsData} from "./types";

const baseRoute = "/api/meetings"
const analyticsRoute = "/api/analytics/hotspot"

class MeetingRepository {
    constructor(private httpClient: AuthTypes.HttpClient) {
        this.exist = this.exist.bind(this)
        this.get = this.get.bind(this)
        this.postHotspotAnalytics = this.postHotspotAnalytics.bind(this)
    }

    async exist(code: string): Promise<boolean> {
        let exist = true
        try {
            await this.get(code)
        } catch {
            exist = false
        }
        return exist
    }

    get(code: string): Promise<Meeting> {
        return this.httpClient.get(`${baseRoute}/${code}`)
    }

    postHotspotAnalytics(hotspotAnalyticsData: HotspotAnalyticsData) {
        return this.httpClient.post(`${analyticsRoute}`, hotspotAnalyticsData)
    }
}

export default MeetingRepository