import * as signalR from "@microsoft/signalr"
import {baseURL} from "Config/constants";

class HubConnectionFactory {
    private hub : string = ""
    constructor() {
        this.build = this.build.bind(this)
    }

    setHub(hub : "game" | "meetings"){
        this.hub = hub
        return this
    }

    build(){
        return new signalR.HubConnectionBuilder()
            .withUrl(`${baseURL}/api/${this.hub}/hub`)
            .configureLogging(signalR.LogLevel.Information)
            .build();
    }
}

export default HubConnectionFactory