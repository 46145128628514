import React, {createContext, Dispatch, SetStateAction, useContext} from "react";
import Meeting from "Core/Meeting/Meeting";
import Video from "Core/Video/Video";
import PlayerBalance from "Core/PlayerBalance/PlayerBalance";
import {MeetingStateData} from "Core/Meeting/types";
import {MeetingStateSetter} from "Core/Meeting/MeetingState";

type lockPopData = {
    show: boolean,
    locked: boolean
}

export type MuteSetter = Dispatch<SetStateAction<boolean>>

type RoomData = {
    video: Video,
    meeting: Meeting,
    playerBalance : PlayerBalance,
    lockPop: lockPopData,
    setLockPop: (lockPop: lockPopData) => void,
    host : boolean
    initialized : boolean,
    setInitialized : (isInitialized : boolean) => void,
    meetingState : MeetingStateData
    setMeetingState : MeetingStateSetter
    setHost : (host : boolean) => void,
    mute ?: boolean,
    setMute: MuteSetter
    micMuted: boolean
    setMicMuted: (muted: boolean) => void
}

export const RoomCtx = createContext({})

export const RoomProvider: React.FC<RoomData> = ({
                                           children,
                                           ...props
                                       }) => (
    <RoomCtx.Provider value={{...props}}>
        {children}
    </RoomCtx.Provider>
);

export const useRoom = () => {
    return useContext(RoomCtx) as RoomData
}