import {useQuiz} from "../QuizCtx";
import {QuizInstance} from "../core/types";
import {initialState} from "./index";

export type useSelectorT = <TSelected = unknown>(
    selector: (state: QuizInstance) => TSelected,
) => TSelected;

const useSelector: useSelectorT = (selector) => {
    const {state = initialState} = useQuiz()

    return selector(state)
}

export default useSelector