import { options, domain } from "./options";
import {
  onAudioMuteStatusChangedListener,
  onScreenSharingStatusChanged,
  Options,
  VideoProvider,
} from "../types";
import TokenProvider from "../TokenProvider/TokenProvider";

class Jitsi extends VideoProvider {
  private api = null as any;
  private muted = true;

  constructor(
    protected container: HTMLDivElement,
    protected tokenProvider: TokenProvider,
    protected meetingId: string
  ) {
    super(container, tokenProvider, meetingId);
    this.init = this.init.bind(this);
    this.remove = this.remove.bind(this);
    this.getContainer = this.getContainer.bind(this);
    this.getMutedStatus = this.getMutedStatus.bind(this);
    this.mute = this.mute.bind(this);
    this.toggleScreenShare = this.toggleScreenShare.bind(this);
    this.onScreenSharingStatusChanged =
      this.onScreenSharingStatusChanged.bind(this);
    this.onAudioMuteStatusChanged = this.onAudioMuteStatusChanged.bind(this);
    this.toggleMute = this.toggleMute.bind(this);
    this.muteAll = this.muteAll.bind(this);
  }

  async init({ screenShare, muteVideo, onError }: Options) {
    const token = await this.tokenProvider.getToken("xureal");
    if (screenShare) {
      options.interfaceConfigOverwrite.TOOLBAR_BUTTONS.push("desktop");
    }

    if (muteVideo) {
      options.configOverwrite.startWithVideoMuted = true;
    } else {
      options.interfaceConfigOverwrite.TOOLBAR_BUTTONS.push("camera");
    }

    // @ts-ignore
    this.api = new JitsiMeetExternalAPI(domain, {
      ...options,
      jwt: token,
      parentNode: this.container,
      roomName: `vpaas-magic-cookie-fd795bae43f847fe87674bcd773ffb6d/${this.meetingId}`,
    });
    this.muted = await this.api.isAudioMuted();
    if (this.api)
      this.api.addListener("errorOccurred", (error: any) => {
        if (onError) onError(error);
        console.error(
          "%cJitsi Error Occurred: ",
          "color: purple; font-size: 20px; font-weight: bold;",
          error
        );
      });
  }

  remove() {
    this.api.dispose();
  }

  getContainer() {
    return this.container;
  }

  onAudioMuteStatusChanged(listener: onAudioMuteStatusChangedListener) {
    this.api?.addListener("audioMuteStatusChanged", listener);
  }

  onScreenSharingStatusChanged(listener: onScreenSharingStatusChanged) {
    this.api?.addListener("screenSharingStatusChanged", listener);
  }

  async getMutedStatus() {
    return await this.api.isAudioMuted();
  }

  async mute() {
    const muted = await this.api.isAudioMuted();
    if (!muted) {
      this.api.executeCommand("toggleAudio");
    }
  }

  toggleMute() {
    this.api?.executeCommand("toggleAudio");
  }

  muteAll() {
    this.muted = true;
    this.api?.executeCommand("muteEveryone");
  }

  async toggleScreenShare() {
    this.api?.executeCommand("toggleShareScreen");
  }
}

export default Jitsi;
