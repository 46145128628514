import React from "react";
import { Box, Centered, Text } from "Commons";
import QuizContainer from "../QuizContainer";
import useStyles from "./styles";
import { Grid } from "@material-ui/core";

const QuizEnded: React.FC = () => {

    const classes = useStyles()

    return (
        <QuizContainer className={classes.container}>
            <Centered classNames={{ root: classes.container }}>
                <Grid style={{ textAlign: 'center', maxWidth: '200px', margin: 'auto' }}>
                    <Box className={classes.root}>
                        <Text type={"info"} >
                            The quiz ended
                        </Text>
                    </Box>
                </Grid>
            </Centered>
        </QuizContainer>
    )
}

export default QuizEnded