import Actions from "./Actions";
import QuizPolicies from "./QuizPolicies";
import QuizDefinitionRepository from "./QuizDefinitionRepository";
import LeaderboardRepository from "./LeaderboardRepository";
import FOAT from "./Foat";

class Quiz {

    constructor(
        private actions: Actions,
        private policies : QuizPolicies,
        private repository : QuizDefinitionRepository,
        private leaderBoardRepository : LeaderboardRepository,
        private foat : FOAT
    ) {
        this.getFoat = this.getFoat.bind(this)
        this.getActions = this.getActions.bind(this)
        this.getPolicies = this.getPolicies.bind(this)
        this.getRepository = this.getRepository.bind(this)
        this.getLeaderboardRepo = this.getLeaderboardRepo.bind(this)
    }

    getFoat(){
        return this.foat
    }

    getActions() {
        return this.actions
    }

    getPolicies(){
        return this.policies
    }

    getRepository(){
        return this.repository
    }

    getLeaderboardRepo(){
        return this.leaderBoardRepository
    }
}

export default Quiz