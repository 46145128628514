import {OnPlayerPointsCallback, OnPlayerPointsData} from "./types";
import PlayerBalanceRepository from "./PlayerBalanceRepository";

class PlayerBalance {

    constructor(private playerBalanceRepo : PlayerBalanceRepository) {
        this.onPlayerPoints = this.onPlayerPoints.bind(this)
    }

    onPlayerPoints(cb : OnPlayerPointsCallback){
        const pointsCallback = (event: {data : OnPlayerPointsData}) => {
            if(event.data.action === "earned") {
                cb(event.data.data)
            }
        }
        window.addEventListener("message", pointsCallback);
        return () => window.removeEventListener("message", pointsCallback);
    }
}

export default PlayerBalance