import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "Commons";
import { httpClient } from "./Config";
import { BrowserRouter } from "react-router-dom";
import appTheme from "./appTheme";
import { ThemeProvider } from "@material-ui/core";
import store from "./Redux/store";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

const SENTRY_URL = process.env.REACT_APP_SENTRY_URL;
if (SENTRY_URL) {
  Sentry.init({
    dsn: SENTRY_URL,
    integrations: [new BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // ! This should be adjusted to a lower rate once
    // ! more bugs are squashed and full prod version is released
    tracesSampleRate: 1.0,
  });
  console.log(
    "%cSentry initialized successfully",
    "color: red; font-weight: bold;"
  );
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={appTheme}>
        <BrowserRouter>
          <AuthProvider
            httpClient={httpClient}
            tokenRefreshIntervalInMinutes={27}
          >
            <App />
          </AuthProvider>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
