import {HttpClient, User} from "Commons/AuthProvider/types";
import {
    Callback,
    onCallback, onQuizEndedCallback,
    OnPlayerAnsweredQuestion,
    onUserJoinedGameCallback,
    onToggleGlobalTriviaAudioCallback,
    OnHostControlledCallback,
    onTriggerHostLeaveGameCallback,
    onUserLeftGameCallback, onQuestionEndedCallback, onNextQuestionCallback
} from "./types";

import * as signalR from "@microsoft/signalr";

class Actions {

    private instanceId: string = ""
    private definitionId: string = ""

    constructor(
        private httpClient: HttpClient,
        private conn: signalR.HubConnection,
        private baseRoute: string,
        private user: User
    ) {
        this.getInstanceId = this.getInstanceId.bind(this)
        this.getDefinitionId = this.getDefinitionId.bind(this)
        this.init = this.init.bind(this)
        this.end = this.end.bind(this)
        this.start = this.start.bind(this)
        this.stop = this.stop.bind(this)
        this.answerQuestion = this.answerQuestion.bind(this)
        this.onNextQuestion = this.onNextQuestion.bind(this)
        this.offNextQuestion = this.offNextQuestion.bind(this)
        this.onPlayerAnsweredQuestion = this.onPlayerAnsweredQuestion.bind(this)
        this.onGameStarted = this.onGameStarted.bind(this)
        this.onGameEnded = this.onGameEnded.bind(this)
        this.offGameEnded = this.offGameEnded.bind(this)
        this.onUserLeftGame = this.onUserLeftGame.bind(this)
        this.onUserJoinedGame = this.onUserJoinedGame.bind(this)
        this.onHostLeftGame = this.onHostLeftGame.bind(this)
        this.onHostJoinedGame = this.onHostJoinedGame.bind(this)
        this.endQuestionCountdown = this.endQuestionCountdown.bind(this)
        this.onQuestionCountdownEnded = this.onQuestionCountdownEnded.bind(this)
        this.leaveGame = this.leaveGame.bind(this)
        this.joinGame = this.joinGame.bind(this)
        this.toggleGlobalTriviaAudio = this.toggleGlobalTriviaAudio.bind(this)
        this.onToggleGlobalTriviaAudio = this.onToggleGlobalTriviaAudio.bind(this)
        this.triggerHostLeaveGame = this.triggerHostLeaveGame.bind(this)
        this.onTriggerHostLeaveGame = this.onTriggerHostLeaveGame.bind(this)
    }

    getInstanceId() {
        return this.instanceId
    }

    getDefinitionId() {
        return this.definitionId
    }

    init(definitionId: string, instanceId: string) {
        this.definitionId = definitionId
        this.instanceId = instanceId
        return this.conn.start()
    }

    async end(isHost: boolean) {
        await this.leaveGame()
        if (isHost) {
            await this.stop()
        }
        return this.conn.stop()
    }

    start() {
        return this.httpClient.put(`${this.baseRoute}/instance/${this.instanceId}/start`, {
            gameInstanceId: this.instanceId,
            userId: this.user.id,
        })
    }

    stop() {
        return this.httpClient.put(`${this.baseRoute}/instance/${this.instanceId}/stop`)
    }

    answerQuestion(questionId: string, pointsEarned: number, answerIndex: number[]) {
        return this.httpClient.put(`${this.baseRoute}/quiz/${this.instanceId}/questions/${questionId}`, {
            gameInstanceId: this.instanceId,
            userId: this.user.id,
            answers: answerIndex,
            questionId,
            pointsEarned
        })
    }

    addPoints(pointsEarned: number) {
        return this.httpClient.put(`api/points/player/${this.user.id}/game/${this.instanceId}`, {
                operator: 1,
                points: pointsEarned
        })
    }

    continueNextQuestion(id: string) {
        return this.conn.invoke('continueNextQuestion', {
            questionId: id,
            gameInstanceId: this.instanceId,
            userId: this.user.id,
        })
    }

    onNextQuestion(callback: onNextQuestionCallback) {
        this.conn.on("nextQuestion", callback)
    }

    offNextQuestion(callback: onNextQuestionCallback) {
        this.conn.off("nextQuestion", callback)
    }

    onPlayerAnsweredQuestion(callback: OnPlayerAnsweredQuestion) {
        this.conn.on("playerAnsweredQuestion", callback)
    }

    onGameStarted(callback: onCallback) {
        this.conn.on("gameStarted", callback)
    }

    onGameEnded(callback: onQuizEndedCallback) {
        this.conn.on("gameEnded", callback)
    }

    offGameEnded() {
        this.conn.off("gameEnded")
        return this
    }

    onHostJoinedGame(callback: Callback) {

        const cb: onUserJoinedGameCallback = (user) => {
            if (user.isHost) {
                callback()
            }
        }
        this.conn.on("userJoinedGame", cb)
        return () => this.conn.off("userJoinedGame", cb)
    }

    onHostLeftGame(callback: Callback) {
        const cb: onUserLeftGameCallback = (user) => {
            if (user.isHost) {
                callback()
            }
        }
        this.conn.on("userLeftGame", cb)
        return () => this.conn.off("userLeftGame", cb)
    }

    onUserJoinedGame(callback: onUserJoinedGameCallback) {
        this.conn.on("userJoinedGame", callback)
        return () => this.conn.off("userJoinedGame", callback)
    }

    onUserLeftGame(callback: onUserLeftGameCallback) {
        this.conn.on("userLeftGame", callback)
        return () => this.conn.off("userLeftGame", callback)
    }

    updateLeaderboard() {
        return this.conn.invoke("updateLeaderboard", {
            gameInstanceId: this.instanceId
        })
    }

    onLeaderboardMustUpdate(cb: Callback) {
        this.conn.on("leaderboardMustUpdate", cb)
    }

    endQuestionCountdown(questionId: string) {
        return this.conn.invoke('endQuestionCountdown', {
            gameInstanceId: this.instanceId,
            questionId,
        })
    }

    onQuestionCountdownEnded(cb: onQuestionEndedCallback) {
        this.conn.on("questionCountdownEnded", cb)
    }

    leaveGame() {
        return this.conn.invoke('leaveGame', {
            gameInstanceId: this.instanceId,
            userId: this.user.id,
        })
    }

    async joinGame(isHost: boolean) {
        if (!isHost) {
            await this.httpClient.put(`${this.baseRoute}/instance/${this.instanceId}/join`)
        }
        await this.conn.invoke('joinGame', {
            gameInstanceId: this.instanceId,
            userId: this.user.id,
            isHost
        })
    }

    onHostControlled(cb: OnHostControlledCallback) {
        this.conn.on("hostControlled", cb)
        return () => this.conn.off("hostControlled", cb)
    }
        
    toggleGlobalTriviaAudio(globalAudioMuted: boolean) {
        return this.conn.invoke('hostControls', {
            gameInstanceId: this.instanceId,
            action: "toggleGlobalTriviaAudio",
            data: {globalAudioMuted}
        })
    }

    onToggleGlobalTriviaAudio(cb: onToggleGlobalTriviaAudioCallback) {
        const toggleGameTriviaAudio: OnHostControlledCallback = (data) => {
            if (data.action === "toggleGlobalTriviaAudio") {
                cb(data)
            }
        }

        return this.onHostControlled(toggleGameTriviaAudio)

    }

    triggerHostLeaveGame() {
        return this.conn.invoke('hostControls', {
            gameInstanceId: this.instanceId,
            action: "triggerHostLeaveGame",
            data: {triggeredHostLeft: true}
        })
    }

    onTriggerHostLeaveGame(cb: onTriggerHostLeaveGameCallback) {
        const toggleGameTriviaAudio: OnHostControlledCallback = (data) => {
            if (data.action === "triggerHostLeaveGame") {
                cb(data)
            }
        }

        return this.onHostControlled(toggleGameTriviaAudio)

    }
}

export default Actions