import React from "react";
import useStyles from "./styles";
import cl from "classnames"

type ImageProps = {
    size?: number,
    src: string,
    Component ?: any,
    className ?: string
    [key: string]: string | number | undefined | boolean | any
}

const Img: React.FC<ImageProps> = (props) => {

    const {src, size, Component = "div", className, ...rest} = props

    const classes = useStyles(size)()

    return (
        <Component className={cl(classes.imageContainer, className)} {...rest}>
            <img src={src} alt=""/>
        </Component>
    )
}

export default Img