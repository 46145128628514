import * as types from "./types"
import {Action} from "./actions";
import {Huddle} from "Core/Huddle/types";

const initialState: Huddle = {
    id: "",
};

const meetingReducer = (state = initialState, action: Action) => {
    switch (action.type) {
        case types.SET_HUDDLE:
            return {
                ...state,
                ...action.payload
            }
        case types.SET_HUDDLE_ACTIVE_PLAYERS:
            return {
                ...state,
            }
        default:
            return state;
    }
};

export default meetingReducer;
