import {AuthTypes} from "Commons";
import {BackendLeaderboard, FrontendLeaderboard} from "./types";
import {toFrontend} from "./mappers";

const baseRoute = "/api/points/leaderboard"

class LeaderboardRepository {
    constructor(private httpClient: AuthTypes.HttpClient) {
        this.getOrCreate = this.getOrCreate.bind(this)
        this.createMeetingLeaderboard = this.createMeetingLeaderboard.bind(this)
        this.getMeetingLeaderBoard = this.getMeetingLeaderBoard.bind(this)
    }

    async getOrCreate(meetingId: string) {
        let leaderboard: FrontendLeaderboard
        try {
            leaderboard = await this.getMeetingLeaderBoard(meetingId)
        } catch {
            leaderboard = await this.createMeetingLeaderboard(meetingId)
        }

        return leaderboard
    }

    async createMeetingLeaderboard(meetingId: string) {
        const leaderboard = await this.httpClient.post<any, BackendLeaderboard>(`${baseRoute}`, {
            name: "Test Meeting Leaderboard",
            meetingId
        })
        return {
            ...leaderboard,
            players: toFrontend(leaderboard.players)
        }
    }

    async getMeetingLeaderBoard(meetingId: string) {
        const leaderboard = await this.httpClient.get<any, BackendLeaderboard>(`${baseRoute}/meeting/${meetingId}`)
        return {
            ...leaderboard,
            players: toFrontend(leaderboard.players)
        }
    }
}

export default LeaderboardRepository