import { makeStyles, Theme, withStyles } from "@material-ui/core";
import { sidebarStyles } from "Commons/index";

export const quizStyleObject = (theme: Theme) => ({
  right: 96,
  background: "#ECECF3",
  // paddingLeft: theme.spacing(0),
  padding: theme.spacing(2, 1),
  overflow: "hidden",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  border: 0,
  zIndex: 100,
  [theme.breakpoints.up("sm")]: {
    width: 321,
  },
  [theme.breakpoints.down("md")]: {
    right: 69,
  },
});

export const scrollableQuizStyleObject = (theme: Theme) => ({
  right: 96,
  background: "#ECECF3",
  // paddingLeft: theme.spacing(0),
  padding: theme.spacing(2, 1),
  overflow: "hidden",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  border: 0,
  zIndex: 100,
  [theme.breakpoints.up("sm")]: {
    width: 321,
  },
  [theme.breakpoints.down("md")]: {
    right: 69,
  },
});

export const meetingStyleObject = (theme: Theme) => ({
  right: 96,
  background: "#ECECF3",
  // paddingLeft: theme.spacing(0),
  overflow: "hidden",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  border: 0,
  zIndex: 100,
  [theme.breakpoints.up("sm")]: {
    width: 321,
  },
  [theme.breakpoints.down("md")]: {
    right: 69,
  },
});

export const withQuizSideBarStyles = withStyles((theme) => ({
  paper: quizStyleObject(theme),
}));

export const withScrollSideBarStyles = withStyles((theme) => ({
  paper: scrollableQuizStyleObject(theme),
}));

export const withMeetingSideBarStyles = withStyles((theme) => ({
  paper: meetingStyleObject(theme),
}));

const useStyles = makeStyles((theme) => ({
  ...sidebarStyles(theme),
  root: {
    backgroundColor: "white!important",
    paddingTop: theme.spacing(4),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0),
    },
  },
  rootWithActiveTab: {
    backgroundColor: "white!important",
    paddingTop: theme.spacing(4),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0),
    },
  },
  drawerInnerContainer: {
    padding: theme.spacing(2, 1),
    borderRadius: theme.spacing(1),
    color: "white",
  },
  activeIconText: {
    color: "black",
    fontSize: 12,
  },
  passiveIconText: {
    fontSize: 12,
    color: "black",
  },
  passiveIcon: {
    color: "#1F69FF !important",
  },
  activeIcon: {
    color: "white",
  },
  activeIconContainer: {
    backgroundColor: "#1F69FF !important",
  },
  passiveIconContainer: {
    backgroundColor: "white !important",
    boxShadow: "0 0 8px rgba(177,177,177,0.54)",
  },
  host: {
    flexGrow: 1,
    [theme.breakpoints.down("xs")]: {
      flexGrow: 0,
    },
  },
  leave: {
    // marginBottom : theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      // flexGrow:
    },
  },
  closeSideBar: {
    marginBottom: theme.spacing(2),
    marginTop: "12px",
  },
  close: {
    marginTop: 1,
    color: "white",
  },
  ranking: {
    flexGrow: 1,
  },
  muteIcon: {
    marginBottom: 20,
  },
  lastItem: {
    flexGrow: 1,
  },
}));

export default useStyles;
