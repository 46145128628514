import axios from "axios";
const { REACT_APP_BASE_URL } = process.env;

const httpClient = axios.create({
    baseURL: REACT_APP_BASE_URL,
    withCredentials: true,
    headers: { Accept: 'application/json' },
    timeout : 6000
})

export default httpClient


