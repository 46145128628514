import {call, put, takeLatest} from "redux-saga/effects"
import {setMeetingLeaderboard} from "./actions";
import * as types from "./types";
import {leaderboardRepo} from "Core/Leaderboard";
import {Action} from "../types";

function* fetchMeetingLeaderboard({payload}: Action<string>) {
    const leaderboard = yield call(leaderboardRepo.getOrCreate, payload);
    console.log(
        "%cfetching leaderboard",
        "color: red; font-weight: bold; font-size: 18px;",
        {leaderboard}
      );
    yield put(setMeetingLeaderboard(leaderboard));
}

function* leaderboardSaga() {
    yield takeLatest(types.FETCH_MEETING_LEADERBOARD, fetchMeetingLeaderboard);
}

export default leaderboardSaga