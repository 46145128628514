import VideoProviderBuilder from "./VideoProviderBuilder";
import Queue from "../Queue";
import {VideoProvider} from "./types";

class Video {

    private queue = new Queue()
    private provider !: VideoProvider

    constructor(private providerBuilder : VideoProviderBuilder) {
        this.getProviderBuilder = this.getProviderBuilder.bind(this)
        this.getProvider = this.getProvider.bind(this)
        this.setProvider = this.setProvider.bind(this)
        this.getQueue = this.getQueue.bind(this)
    }

    getProviderBuilder(){
        return this.providerBuilder
    }

    getProvider(){
        return this.provider
    }

    setProvider(provider : VideoProvider){
        this.provider = provider
        return this
    }

    getQueue(){
        return this.queue
    }
}

export default Video