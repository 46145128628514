import type {AuthTypes} from "Commons";
import {Platform} from "./types";

const baseRoute = "api/meetings/video/token"

class TokenProvider {

    constructor(private httpClient: AuthTypes.HttpClient) {
        this.getToken = this.getToken.bind(this)
    }

    async getToken(platform: Platform) : Promise<string> {
        const token = await this.httpClient.get<null, {accessToken : string}>(`${baseRoute}?platform=${platform}`)
        return token.accessToken
    }
}

export default TokenProvider