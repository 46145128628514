import MeetingController from "./MeetingController";

class ControllerBuilder {

    private originTarget = ""
    private meetingWindow : Window = window
    private host : boolean = false

    constructor() {
        this.setOriginTarget = this.setOriginTarget.bind(this)
        this.setWindow = this.setWindow.bind(this)
        this.build = this.build.bind(this)
    }

    setOriginTarget(originTarget : string){
        this.originTarget = originTarget
        return this
    }

    setWindow(window : Window){
        this.meetingWindow = window
        return this
    }

    setHost(host : boolean){
        this.host = host
        return this
    }

    build(){
        return new MeetingController(this.meetingWindow, this.originTarget, this.host)
    }
}

export default ControllerBuilder