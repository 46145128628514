import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    container : {
        position : "relative",
        height: '100%'
    },
    root : {
        borderRadius : 24,
        padding : 24,
        background : "#2D2D2D",
        color : "white",
        [theme.breakpoints.down('sm')]: {
            padding : 24,
        },
    }
}))

export default useStyles