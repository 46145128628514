import {useEffect, useState} from "react";
import {useHistory, useRouteMatch} from "react-router-dom";
import {RouteBuilder} from "Utils";

const useOnInitialLoad = () => {
    const [initialLoad, setInitialLoaded] = useState(true)
    const history = useHistory()
    const match = useRouteMatch<{ id: string }>(RouteBuilder.room.id.build())!

    useEffect(() => {
        if (initialLoad && match?.params?.id) {
            history.push(RouteBuilder.room.param(match?.params?.id).build())
        }
        setInitialLoaded(true)
    }, [initialLoad, history, match?.params?.id])
}

export default useOnInitialLoad