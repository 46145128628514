import {MutableRefObject, useCallback, useEffect, useRef, useState} from 'react';

interface UseTimerProps {
    initialTime: number;
    interval: number;
}

interface ReturnValue {
    reset: () => void
    start: () => void
    time: MutableRefObject<any>
}

const useTimer = ({initialTime = 0, interval = 1000}: UseTimerProps): ReturnValue => {

    const [started, setStarted] = useState(false)
    const timeRef = useRef(initialTime)

    const reset = useCallback(() => {
        timeRef.current = initialTime
        setStarted(false)
    }, [initialTime])

    const start = useCallback(() => {
        setStarted(true)
    }, [])

    useEffect(() => {
        let intervalId: NodeJS.Timeout | null = null;

        if (started) {
            intervalId = setInterval(() => {
                if(timeRef.current - interval < 0){
                    setStarted(false)
                    return intervalId && clearInterval(intervalId)
                }
                timeRef.current = timeRef.current - interval
            }, interval);
        } else if (intervalId) {
            clearInterval(intervalId)
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [interval, started])

    return {
        reset,
        start,
        time: timeRef
    }
}

export default useTimer