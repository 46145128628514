import {makeStyles, Theme} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: theme.spacing(4),
        height: "102vh",
        background: theme.backgroundColors.main,
        position: "absolute",
        zIndex: theme.zIndex.drawer + 1,
        marginBottom: 0
    },
    sidebarItem: {
        marginBottom: theme.spacing(2.5),
        [theme.breakpoints.down('xs')]: {
            marginBottom: 0
        },
    },
    iconContainer: {
        background: theme.backgroundColors.dark,
        borderRadius: "50%",
        marginBottom: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            marginBottom: theme.spacing(1),
        },
    },
    activeIcon: {
        background: "white",
        color: theme.backgroundColors.dark
    },
    passiveIcon: {
        color: "white"
    },
    drawerHeader: {
        minWidth: 240,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        marginBottom: theme.spacing(2)
    },
    closeIcon: {
        padding: 0,
        color: "black"
    }
} as const))

export const sidebarStyles = (theme: Theme) => ({
    root: {
        position: 'relative' as any,
        paddingTop: theme.spacing(4),
        height: "100vh",
        background: theme.backgroundColors.main,
        zIndex: theme.zIndex.drawer + 1,
        marginBottom: 0
    },
    iconContainer: {
        background: theme.backgroundColors.dark,
        borderRadius: "50%",
        marginBottom: theme.spacing(2)
    },
    activeIcon: {
        background: "white",
        color: theme.backgroundColors.dark
    },
    passiveIcon: {
        color: "white"
    },
    closeIcon: {
        padding: 0,
        color: "white"
    }
})

export default useStyles