import {AuthTypes} from "Commons";
import {PlayerBalance, PlayerBalanceRequestData} from "./types";

const baseRoute = "/api/points/player"

class PlayerBalanceRepository {
    constructor(private httpClient: AuthTypes.HttpClient) {
        this.get = this.get.bind(this)
        this.add = this.add.bind(this)
    }

    async get(playerId: string, meetingId : string): Promise<PlayerBalance> {
        let balance : PlayerBalance
        try {
            balance = await this.httpClient.get(`${baseRoute}/${playerId}/meeting/${meetingId}`)
        } catch {
            balance = { userId : playerId, points : 0, }
        }

        return balance
    }

    add({userId, meetingId , points} : PlayerBalanceRequestData){
      return this.httpClient.put(`${baseRoute}/${userId}/meeting/${meetingId}`, {operator: "Add", points})
    }
}

export default PlayerBalanceRepository