import * as signalR from "@microsoft/signalr";
import {
  HostCreatedGameCallback,
  MeetingCallBack,
  MeetingConnectionState,
  MeetingStateCallback,
  MeetingStateData,
  NavigationScene,
  OnMeetingStateCallback,
  Callback,
  OnHostControlledCallback,
  OnHostGuidesTourCallback,
  OnHostJoinedAndLeftCallback,
  OnHostLockedAreaCallback,
  OnHostNavigateCallback,
  OnHostShowContentCallback,
  OnQuizInviteCallback,
  OnToggleScreenShareCallback,
  OnSetMuteAllCallback,
  onParticipantUnmutedCallback,
} from "./types";
import { AuthTypes } from "Commons";

class Actions {
  private state: MeetingConnectionState = "";
  private meetingId: string = "";
  private user: AuthTypes.User = {} as any;
  private meetingCode: string = "";

  constructor(private conn: signalR.HubConnection) {
    this.getState = this.getState.bind(this);
    this.getId = this.getId.bind(this);
    this.setUser = this.setUser.bind(this);
    this.start = this.start.bind(this);
    this.stop = this.stop.bind(this);
    this.sendEndMeeting = this.sendEndMeeting.bind(this);
    this.handleEndMeeting = this.handleEndMeeting.bind(this);
    this.onUserJoined = this.onUserJoined.bind(this);
    this.onUserLeft = this.onUserLeft.bind(this);
    this.hostStartedTheGame = this.hostStartedTheGame.bind(this);
    this.onHostCreatedGame = this.onHostCreatedGame.bind(this);
    this.hostControls = this.hostControls.bind(this);
    this.onHostControlled = this.onHostControlled.bind(this);
    this.onHostJoined = this.onHostJoined.bind(this);
    this.onHostLeft = this.onHostLeft.bind(this);
    this.hostLockScene = this.hostLockScene.bind(this);
    this.onHostShowContent = this.onHostShowContent.bind(this);
    this.onHostLockedArea = this.onHostLockedArea.bind(this);
    this.hostNavigate = this.hostNavigate.bind(this);
    this.onHostNavigate = this.onHostNavigate.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.hostGuidesTour = this.hostGuidesTour.bind(this);
    this.onHostGuidesTour = this.onHostGuidesTour.bind(this);
    this.broadcastMeetingState = this.broadcastMeetingState.bind(this);
    this.onMeetingState = this.onMeetingState.bind(this);
    this.onMeetingStateRequest = this.onMeetingStateRequest.bind(this);
    this.meetingStateRequest = this.meetingStateRequest.bind(this);
    // this.leave = this.leave.bind(this);
    this.join = this.join.bind(this);
    this.inviteToQuiz = this.inviteToQuiz.bind(this);
    this.onInviteToQuiz = this.onInviteToQuiz.bind(this);
    this.toggleScreenShare = this.toggleScreenShare.bind(this);
    this.onToggleScreenShare = this.onToggleScreenShare.bind(this);
    this.updateLeaderBoard = this.updateLeaderBoard.bind(this);
    this.onUpdateLeaderBoard = this.onUpdateLeaderBoard.bind(this);
    this.participantUnmuted = this.participantUnmuted.bind(this);
    this.onParticipantUnmuted = this.onParticipantUnmuted.bind(this);
    this.muteAllMicrophones = this.muteAllMicrophones.bind(this);
    this.onMuteAllMicrophones = this.onMuteAllMicrophones.bind(this);
    this.onShowSurvey = this.onShowSurvey.bind(this);
  }

  getState() {
    return this.state;
  }

  getId() {
    return this.meetingId;
  }

  getCode() {
    return this.meetingCode;
  }

  setUser(user: AuthTypes.User) {
    this.user = user;
  }

  async start(code: string, id: string) {
    this.meetingCode = code;
    this.meetingId = id;
    this.state = "connecting";
    const conn = await this.conn.start();
    this.state = "connected";
    return conn;
  }

  onClose(callback: (error?: Error) => void) {
    // pass a callback to the onclose method of signalR
    this.conn.onclose(callback);
  }

  stop() {
    this.state = "";
    return this.conn.stop();
  }

  async sendEndMeeting() {
    return this.conn.invoke("hostControls", {
      meetingId: this.meetingId,
      action: "host-ended-meeting",
    });
  }

  async handleEndMeeting() {
    this.state = "ended";
    localStorage.setItem("meetingEnded", this.meetingCode);
    return this.conn.stop();
  }

  onHostEndMeeting(callback: any) {
    const showContent: OnHostControlledCallback = (data) => {
      if (data.action === "host-ended-meeting") {
        callback();
      }
    };
    return this.onHostControlled(showContent);
  }

  onUserJoined(callback: MeetingCallBack) {
    this.conn.on("userJoined", callback);
    return () => this.conn.off("userJoined", callback);
  }

  onUserLeft(callback: MeetingCallBack) {
    this.conn.on("userLeft", callback);
    return () => this.conn.off("userLeft", callback);
  }

  hostStartedTheGame(gameInstanceId: string, gameDefinitionId: string) {
    return this.conn.invoke("meetingJoinGame", {
      meetingId: this.meetingId,
      fullName: this.user.firstName,
      userId: this.user.id,
      gameInstanceId,
      gameDefinitionId,
    });
  }

  onHostCreatedGame(cb: HostCreatedGameCallback) {
    this.conn.on("hostCreatedGame", cb);
    return () => this.conn.off("hostCreatedGame", cb);
  }

  hostControls(action: string, data: Record<string, string>) {
    return this.conn.invoke("hostControls", {
      meetingId: this.meetingId,
      action,
      data,
    });
  }

  onHostControlled(cb: OnHostControlledCallback) {
    this.conn.on("hostControlled", cb);
    return () => this.conn.off("hostControlled", cb);
  }

  onHostJoined(cb: OnHostJoinedAndLeftCallback) {
    this.conn.on("hostJoined", cb);
    return () => this.conn.off("hostJoined", cb);
  }

  onHostLeft(cb: OnHostJoinedAndLeftCallback) {
    this.conn.on("hostLeft", cb);
    return () => this.conn.off("hostLeft", cb);
  }

  hostLockScene(scene: NavigationScene, locked: boolean) {
    return this.conn.invoke("hostControls", {
      meetingId: this.meetingId,
      action: "lockScene",
      data: {
        scene,
        locked,
      },
    });
  }

  onHostShowContent(cb: OnHostShowContentCallback) {
    const showContent: OnHostControlledCallback = (data) => {
      if (data.action === "showContent") {
        cb(data.data);
      }
    };

    return this.onHostControlled(showContent);
  }

  onHostLockedArea(cb: OnHostLockedAreaCallback) {
    const lockHandler: OnHostControlledCallback = (data) => {
      if (data.action === "lockScene") {
        cb(data.data);
      }
    };

    return this.onHostControlled(lockHandler);
  }

  hostNavigate(scene: NavigationScene) {
    return this.conn.invoke("hostControls", {
      meetingId: this.meetingId,
      action: "navigateScene",
      data: {
        scene,
      },
    });
  }

  onHostNavigate(cb: OnHostNavigateCallback) {
    const navigateHandler: OnHostControlledCallback = (data) => {
      if (data.action === "navigateScene") {
        cb(data.data);
      }
    };

    return this.onHostControlled(navigateHandler);
  }

  closeModal() {
    return this.conn.invoke("hostControls", {
      meetingId: this.meetingId,
      action: "closeModal",
    });
  }

  onCloseModal(cb: Callback) {
    const closeModalHandler: OnHostControlledCallback = (data) => {
      if (data.action === "closeModal") {
        cb();
      }
    };

    return this.onHostControlled(closeModalHandler);
  }

  hostGuidesTour(enabled: boolean) {
    return this.conn.invoke("hostControls", {
      meetingId: this.meetingId,
      action: "guideTour",
      data: {
        enabled,
      },
    });
  }

  onHostGuidesTour(cb: OnHostGuidesTourCallback) {
    const guideTourHandler: OnHostControlledCallback = (data) => {
      if (data.action === "guideTour") {
        cb(data.data);
      }
    };

    return this.onHostControlled(guideTourHandler);
  }

  broadcastMeetingState(data: MeetingStateData, userId?: string) {
    return this.conn.invoke("hostControls", {
      meetingId: this.meetingId,
      action: "meetingState",
      userId,
      data,
    });
  }

  onMeetingState(cb: MeetingStateCallback) {
    const meetingStateHandler: OnHostControlledCallback = (data) => {
      if (data.action === "meetingState") {
        cb(data.data);
      }
    };

    return this.onHostControlled(meetingStateHandler);
  }

  onMeetingStateRequest(cb: OnMeetingStateCallback) {
    const meetingStateHandler: OnHostControlledCallback = (data) => {
      if (data.action === "meetingStateRequest") {
        cb(data.data);
      }
    };

    return this.onHostControlled(meetingStateHandler);
  }

  meetingStateRequest(userId?: string) {
    return this.conn.invoke("hostControls", {
      meetingId: this.meetingId,
      action: "meetingStateRequest",
      userId,
      data: {},
    });
  }

  // leave(isHost : boolean) {
  //   alert("calling leaveMeeting ")
  //   alert({isHost})

  //   return this.conn.invoke('leaveMeeting', {
  //       meetingId: this.meetingId,
  //       fullName: this.user.firstName,
  //       userId: this.user.id,
  //       email: this.user.email,
  //       isHost
  //   })
  // }

  join(isHost: boolean) {
    return this.conn.invoke("joinMeeting", {
      meetingId: this.meetingId,
      fullName: this.user.firstName,
      userId: this.user.id,
      email: this.user.email,
      isHost,
    });
  }

  inviteToQuiz(
    userId: string,
    gameInstanceId: string,
    gameDefinitionId: string
  ) {
    return this.conn.invoke("hostControls", {
      meetingId: this.meetingId,
      action: "quizInvite",
      userId,
      data: {
        gameInstanceId,
        gameDefinitionId,
      },
    });
  }

  onInviteToQuiz(cb: OnQuizInviteCallback) {
    const meetingStateHandler: OnHostControlledCallback = (data) => {
      if (data.action === "quizInvite") {
        cb(data.data);
      }
    };

    return this.onHostControlled(meetingStateHandler);
  }

  toggleScreenShare(sharing: boolean) {
    return this.conn.invoke("hostControls", {
      meetingId: this.meetingId,
      action: "toggleScreenShare",
      data: { sharing },
    });
  }

  onToggleScreenShare(cb: OnToggleScreenShareCallback) {
    const toggleScreenShareHandler: OnHostControlledCallback = (data) => {
      if (data.action === "toggleScreenShare") {
        cb(data.data);
      }
    };

    return this.onHostControlled(toggleScreenShareHandler);
  }

  muteAllMicrophones() {
    return this.conn.invoke("hostControls", {
      meetingId: this.meetingId,
      action: "mute-everyone",
    });
  }

  onMuteAllMicrophones(cb: OnSetMuteAllCallback) {
    const muteAllMicrophonesHandler: OnHostControlledCallback = (data) => {
      if (data.action === "mute-everyone") {
        cb();
      }
    };

    return this.onHostControlled(muteAllMicrophonesHandler);
  }

  participantUnmuted() {
    return this.conn.invoke("hostControls", {
      meetingId: this.meetingId,
      action: "participant-unmuted",
    });
  }

  onParticipantUnmuted(cb: onParticipantUnmutedCallback) {
    const participantUnmutuedHandled: OnHostControlledCallback = (data) => {
      if (data.action === "participant-unmuted") {
        cb();
      }
    };

    return this.onHostControlled(participantUnmutuedHandled);
  }

  updateLeaderBoard() {
    return this.conn.invoke("hostControls", {
      meetingId: this.meetingId,
      action: "updateLeaderboard",
      data: {},
    });
  }

  onUpdateLeaderBoard(cb: Callback) {
    const onLeaderboardUpdateHandler: OnHostControlledCallback = (data) => {
      if (data.action === "updateLeaderboard") {
        cb();
      }
    };

    return this.onHostControlled(onLeaderboardUpdateHandler);
  }

  onShowSurvey(cb: Callback) {
    const showSurveyHandler: OnHostControlledCallback = (data) => {
      if (data.action === "showSurvey") {
        cb();
      }
    };

    return this.onHostControlled(showSurveyHandler);
  }
}

export default Actions;
