import {HttpClient} from "Commons/AuthProvider/types";
import {PlayerPoints, QuizInstance} from "./types";

const baseRoute = "/api/game/instance"

class QuizInstanceRepository {
    constructor(private httpClient: HttpClient) {
        this.get = this.get.bind(this)
    }

    async get(id: string) : Promise<QuizInstance> {
        return this.httpClient.get(`${baseRoute}/${id}`)
    }

    getPlayerPoints(playerId : string, instanceId : string) : Promise<PlayerPoints>{
        return this.httpClient.get(`/api/points/player/${playerId}/game/${instanceId}`)
    }
}

export default QuizInstanceRepository