import {QuizInstance, StoredQuiz} from "./types";
import {User} from "Commons/AuthProvider/types";
import QuizDefinitionRepository from "./QuizDefinitionRepository";

class QuizSpecs {

    constructor(
        private instance: QuizInstance,
        private user: User,
        private quizDefinitionRepo : QuizDefinitionRepository
    ) {
        this.started = this.started.bind(this)
        this.ended = this.ended.bind(this)
        this.playerAborted = this.playerAborted.bind(this)
        this.canJoin = this.canJoin.bind(this)
    }

    async started() : Promise<boolean>{
        const instance = await this.quizDefinitionRepo.getInstance(this.instance.id)
        return instance.gameState === "InProgress"
    }

    ended(){
        return this.instance.gameState === "Ended"
    }

    playerAborted() {
        if (this.instance.players[this.user.id] === "Aborted") {
            return true
        }
    }

    async safeToLeave(instanceId : string){
        const instance = await this.quizDefinitionRepo.getInstance(instanceId)
        return instance.gameState === "Ended"
    }

    canJoin() {
        return this.instance.gameState === "Created" || !this.playerAborted()

    }

    playable(storedQuiz : StoredQuiz | null){
        if(!storedQuiz){
            return false
        }
        return storedQuiz.time + (300 * 1000) > Date.now()
    }
}

export default QuizSpecs