import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(6, 5),
        height: "100%",
        minHeight: "100vh",
        color: "white",
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(2),
        },
    }
}))

export default useStyles