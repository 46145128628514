import TokenProvider from "./TokenProvider/TokenProvider";

export abstract class VideoProvider {
  protected constructor(
    protected container: HTMLDivElement,
    protected tokenProvider: TokenProvider,
    protected meetingId: string
  ) {}

  abstract init(options: Options): Promise<void>;
  abstract getContainer(): HTMLDivElement;
  abstract remove(): void;
  abstract getMutedStatus(): Promise<boolean>;
  abstract mute(): Promise<void>;
  abstract onAudioMuteStatusChanged(
    listener: onAudioMuteStatusChangedListener
  ): void;
  abstract onScreenSharingStatusChanged(
    listener: onScreenSharingStatusChanged
  ): void;
  abstract toggleScreenShare(): void;
  abstract toggleMute(): void;
  abstract muteAll(): void;
}

export interface Options {
  screenShare?: boolean;
  muteVideo?: boolean;
  onError?: (error: any) => void;
}

export interface onAudioMuteStatusChangedData {
  muted: boolean;
}

export interface onScreenSharingStatusChangedData {
  on: boolean;
}

export type onAudioMuteStatusChangedListener = (
  data: onAudioMuteStatusChangedData
) => void;
export type onScreenSharingStatusChanged = (
  data: onScreenSharingStatusChangedData
) => void;

export type ProviderName = "Jitsi" | "Zoom";
