import React from "react";
import cl from "classnames";
import useStyles from "./styles";

type CenteredProps = {
    classNames ?: {
        root ?: string,
        centered ?: string
    }
}

const Centered : React.FC<CenteredProps> = ({children, classNames}) => {

    const classes = useStyles()

    return (
        <div className={cl(classes.root, classNames?.root)}>
            <div className={cl(classes.centered, classNames?.centered)}>
                {children}
            </div>
        </div>
    )
}

export default Centered