import {useCallback, useEffect, useState} from "react";
import {useVideo} from "../../Components/Video/VideoCtx";

export type LayoutProps = {
    defaultActiveTab?: string
}

const useLayout = ({defaultActiveTab = ""}: LayoutProps) => {
    const [activeTab, setActiveTab] = useState<string>(defaultActiveTab)
    const {closeVideo, openVideo} = useVideo()

    useEffect(() => {
        setActiveTab(defaultActiveTab)
    }, [defaultActiveTab])

    const handleVideo = useCallback((tab: string) => {
        if (tab === "" || tab !== "Video") {
            closeVideo()
        } else if (tab === "Video") {
            openVideo()
        }
    }, [openVideo, closeVideo])

    const onActiveTabClick = useCallback((tab: string) => () => {
        const newActiveTab = activeTab === tab ? "" : tab
        handleVideo(newActiveTab)
        setActiveTab(newActiveTab)
    }, [activeTab, handleVideo])

    const close = useCallback(() => {
        setActiveTab("")
    }, [])

    return {
        onActiveTabClick,
        activeTab,
        setActiveTab,
        close
    }
}

export default useLayout