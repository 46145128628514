import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        minHeight: theme.spacing(6),
        [theme.breakpoints.down('sm')]: {
            minHeight: theme.spacing(4)
        }
    },
    headerTitle: {
        marginBottom: '0!important',
        whiteSpace: "nowrap",
        [theme.breakpoints.down('sm')]: {
            fontSize: "16px"
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: "14px"
        },
    },
    timeContainer: {},
    timeIconContainer: {
        [theme.breakpoints.down('sm')]: {
            // maxWidth: theme.spacing(7),
            padding: 0
        }
    },
    timerIcon : {
        fontSize : 56,
        color : "white",
        margin: theme.spacing(0, 2, 0, 0),
        [theme.breakpoints.down('md')]: {
            margin: theme.spacing(0, 1, 0, 0),
            fontSize : "calc(30px + 1vw)",
        },
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(0, 0.2, 0, 0),
            fontSize : "calc(30px + 0.5vw)",
        },
    },
    completed : {
        color : "#E83838"
    },
    countdownTimer : {
        "& div" :{
            [theme.breakpoints.down('sm')]: {
                marginLeft: theme.spacing(.2),
                fontSize: "calc(26px + 0.25vw)",
                fontWeight: 500,
            },
        },
    },
    remainingTime: {
        color : "white",
        fontSize : 40,
        // [theme.breakpoints.down('xs')]: {
        //     marginLeft: theme.spacing(-3),
        // },
    },
    activPayerItems: {
        marginRight: 0,
        [theme.breakpoints.down('xs')]: {
            padding: 0,
        }
    },
    activeDot : {
        width : 12,
        height : 12,
        backgroundColor : "#00EA73",
        borderRadius : "50%",
        // marginTop : theme.spacing(-0.5)
    },
    closeIcon: {
        padding: 0,
        color: "white",
        marginLeft: theme.spacing(-0.5)
    },
    activePlayerTxt: {
        [theme.breakpoints.down('xs')]: {
            fontSize: "14px",
            padding: 0,
        },
    }
}))

export default useStyles