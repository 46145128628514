import ControllerBuilder from "./ControllerBuilder";
import Actions from "./Actions";
import Queue from "../Queue";
import MeetingController from "./MeetingController";
import MeetingState from "./MeetingState";
import MeetingRepository from "./MeetingRepository";
import MeetingPolicies from "./MeetingPolicies";

class Meeting {

    private queue = new Queue()
    private controllerQueue = new Queue()
    private controller !: MeetingController

    constructor(
        private controllerBuilder: ControllerBuilder,
        private actions: Actions,
        private state : MeetingState,
        private meetingRepo : MeetingRepository,
        private policies : MeetingPolicies
    ) {
        this.getState = this.getState.bind(this)
        this.getRepository = this.getRepository.bind(this)
        this.getActions = this.getActions.bind(this)
        this.getControllerBuilder = this.getControllerBuilder.bind(this)
        this.setController = this.setController.bind(this)
        this.getController = this.getController.bind(this)
        this.getPolicies = this.getPolicies.bind(this)
        this.getQueue = this.getQueue.bind(this)
    }

    getState(){
        return this.state
    }

    getRepository(){
        return this.meetingRepo
    }

    getActions() {
        return this.actions
    }

    getControllerBuilder() {
        return this.controllerBuilder
    }

    setController(controller : MeetingController){
        this.controller = controller
        return this
    }

    getController() {
        return this.controller
    }

    getControllerQueue(){
        return this.controllerQueue
    }

    getPolicies(){
        return this.policies
    }

    getQueue(){
        return this.queue
    }
}

export default Meeting