import {Meeting, MeetingEventData} from "Core/Meeting/types";
import * as types from "./types";

export const setMeeting = (meeting: Meeting) => ({
    type: types.SET_MEETING,
    payload: meeting
} as const)

export const setPresentParticipant = (meetingEventData: MeetingEventData) => ({
    type: types.SET_PRESENT_PARTICIPANTS,
    payload: meetingEventData
} as const)

export const removePresentParticipant = (participantData: MeetingEventData) => ({
    type: types.REMOVE_MEETING_PARTICIPANT,
    payload: participantData
} as const)

export const setPresentHostId = (hostId: string) => ({
    type: types.SET_PRESENT_HOST_ID,
    payload: hostId
} as const)

export const fetchMeeting = (meetingId: Meeting["id"]) => ({
    type: types.FETCH_MEETING,
    payload: meetingId
} as const)

export const sendHotspotAnalytics = (analyticsObj: any) => ({
    type: types.POST_HOTSPOT_ANALYTICS,
    payload: analyticsObj
} as const)

export const sendError = (error: {title: string, body: string}) => ({
    type: types.SET_ERROR,
    payload: error
} as const)

export const clearError = () => ({
    type: types.CLEAR_ERROR,
} as const)

export const reset = () => ({
    type: types.RESET,
} as const)

export type Action = ReturnType<typeof setMeeting>
    | ReturnType<typeof setPresentParticipant>
    | ReturnType<typeof removePresentParticipant>
    | ReturnType<typeof setPresentHostId>
    | ReturnType<typeof fetchMeeting>
    | ReturnType<typeof sendHotspotAnalytics>
    | ReturnType<typeof sendError>
    | ReturnType<typeof clearError>
    | ReturnType<typeof reset>