import {makeStyles} from "@material-ui/core";
import {Color} from "./types";

const colorMap = {
    dark : "black",
    light : "white"
}

const useStyles = (color : Color) => makeStyles(theme => ({
    root: {
        marginBottom: 16,
        color: colorMap[color]
    }
}))

export default useStyles