import React from "react";

import {useQuiz} from "../QuizCtx";

import useStyles from "./styles";

const TriviaLogo : React.FC = () => {
    const {triviaLogo} = useQuiz()
    const classes = useStyles()

    return (
        <img src={triviaLogo} alt="" className={classes.triviaLogo}/>
    )
}

export default TriviaLogo