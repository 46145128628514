import React, { useCallback, useEffect, useRef, useState } from "react";
import { Drawer, Grid } from "@material-ui/core";
import useStyles from "./styles";
import { useRoom } from "../Room/RoomCtx";
import { Conditional, DrawerHeader } from "Commons";
import { useRouteMatch, useLocation } from "react-router-dom";
import { matchPath } from "react-router";
import { RouteBuilder } from "Utils";
import { useVideo } from "./VideoCtx";
import {
  meetingStyleObject,
  withDynamicSideBarStyles,
} from "Components/Room/Layout/styles";
import { quizStyleObject } from "Commons/Quiz/Layout/styles";
import { VideoStatus } from "Core/Meeting/types";
import { tokenProvider } from "Core/Video/TokenProvider";
import cl from "classnames";
import MutedIcon from "assets/icons/video/muted-white.png";
import UnmutedBlackIcon from "assets/icons/video/unmuted-black.png";
import UnmutedGreenIcon from "assets/icons/video/unmuted-green.png";
import { useDispatch } from "react-redux";
import { sendError } from "Redux/meeting/actions";

const Video: React.FC = () => {
  const classes = useStyles();
  const [allMuted, setAllMuted] = useState(false);

  const {
    video,
    initialized,
    host,
    meeting,
    meetingState: { sharing },
    micMuted,
    setMicMuted,
  } = useRoom();
  const { openVideo, open, closeVideo } = useVideo();
  const location = useLocation();
  const videoStatusRef = useRef<VideoStatus>("ended");
  const videoContainerRef = useRef<HTMLDivElement>(null);
  const match = useRouteMatch<{ id: string }>(RouteBuilder.room.id.build())!;
  const meetingActions = meeting.getActions();
  const meetingStateController = meeting.getState();

  const dispatch = useDispatch();

  const isQuiz = !!matchPath(
    location.pathname,
    RouteBuilder.room.id.trivia.quizId.build()
  );
  const drawerClasses = withDynamicSideBarStyles(
    isQuiz ? quizStyleObject : meetingStyleObject
  )();

  useEffect(() => {
    if (meeting.getActions().getState() === "ended") closeVideo();
    closeVideo();
  }, [meeting, closeVideo]);

  useEffect(() => {
    closeVideo();
  }, [location, closeVideo]);

  const removeVideo = useCallback(() => {
    video.getProvider().remove();
  }, [video]);

  useEffect(() => {
    window.addEventListener("unload", removeVideo);
  }, [removeVideo]);

  const handleErrorDispatch = useCallback(
    (err) => {
      dispatch(
        sendError({
          title: "",
          body: "Please refresh your browser. If you continue to experience difficulties, please contact your meeting's Host.",
        })
      );
      console.error("Video provider error: ", err);
    },
    [dispatch]
  );

  const handleVideoStart = useCallback(async () => {
    await video.getProvider().mute();
    setMicMuted(true);
    setAllMuted(true); // might need to change this in the case the host opens a video when guided tour is off
    videoStatusRef.current = "started";
  }, [video, setMicMuted]);

  const handleVideoPaused = useCallback(() => {
    videoStatusRef.current = "paused";
  }, []);

  const handleVideoEnded = useCallback(async () => {
    videoStatusRef.current = "ended";
  }, []);

  useEffect(() => {
    let offStarted = () => {},
      offPaused = () => {},
      offEnded = () => {},
      offReady = () => {},
      offMeetingReady = () => {},
      offModalInteraction = () => {},
      offScreenShare = () => {};

    if (initialized) {
      offReady = video.getQueue().onReady(async () => {
        const provider = video.getProvider();
        const muteVideo = meeting.getPolicies().shouldHideVideo(host);
        await provider.init({
          screenShare: false,
          muteVideo,
          onError: handleErrorDispatch,
        });

        // if(host){
        //     offMeetingReady = meeting.getQueue().onReady(async () => {
        //         setTimeout(() => {
        //             provider.onScreenSharingStatusChanged(({on}) => {
        //                 meetingActions.toggleScreenShare(on)
        //                 meetingStateController.set({sharing: on})
        //                 if (on) closeVideo()
        //             })
        //              provider.toggleScreenShare()
        //         }, 2000)
        //     })
        // }

        // if (!host) {
        //   offScreenShare = meetingActions.onToggleScreenShare(({ sharing }) => {
        //     meetingStateController.set({ sharing });
        //     if (!sharing) {
        //       screenUtils.exitFullscreen();
        //       closeVideo();
        //     } else openVideo();
        //   });
        // }

        const controller = meeting.getController();
        offStarted = controller.onVideoControls("started", handleVideoStart);
        offPaused = controller.onVideoControls("paused", handleVideoPaused);
        offEnded = controller.onVideoControls("ended", handleVideoEnded);
        offModalInteraction = controller.onModalInteraction(closeVideo);
        provider.onAudioMuteStatusChanged(async ({ muted }) => {
          if (videoStatusRef.current === "started" && !host && !muted) {
            await video.getProvider().mute();
            setMicMuted(true);
          } else {
            setMicMuted(muted);
          }
        });

        if (host) {
          meetingActions.onParticipantUnmuted(() => {
            setAllMuted(false);
          });
        }

        !muteVideo && openVideo();
      });
    }

    return () => {
      offStarted();
      offPaused();
      offEnded();
      offReady();
      offMeetingReady();
      offModalInteraction();
      offScreenShare();
      video.getQueue().setReady(false);
      videoStatusRef.current = "ended";
    };
  }, [
    video,
    meeting,
    initialized,
    openVideo,
    host,
    handleVideoStart,
    handleVideoPaused,
    handleVideoEnded,
    closeVideo,
    meetingActions,
    meetingStateController,
    setMicMuted,
    handleErrorDispatch,
  ]);

  useEffect(() => {
    const videoContainer = videoContainerRef.current;
    if (initialized && videoContainer && match?.params?.id) {
      video
        .setProvider(
          video
            .getProviderBuilder()
            .setProvider("Jitsi")
            .setTokenProvider(tokenProvider)
            .setContainer(videoContainer)
            .setMeetingId(match.params.id)
            .build()
        )
        .getQueue()
        .setReady();
    }
  }, [video, match?.params?.id, initialized]);

  const toggleMuteMic = async () => {
    // if you are the host, toggle mute at any time
    // if you are a participant, and a video is not playing, toggle mute
    if (!host && videoStatusRef.current === "started") {
      return;
    }
    if (host || (!host && videoStatusRef.current !== "started")) {
      video.getProvider().toggleMute();
    }
    if (!host && micMuted && videoStatusRef.current !== "started")
      meeting.getActions().participantUnmuted();
    const muted = await video.getProvider().getMutedStatus();
    setMicMuted(muted);
  };

  const handleMuteAll = () => {
    video.getProvider().muteAll();
    setAllMuted(true);
  };

  return (
    <Conditional cond={initialized}>
      <Grid
        container
        wrap="nowrap"
        id="video-controls-container"
        className={classes.muteRoot}
      >
        <Grid item>
          <Conditional
            cond={micMuted}
            onFalse={
              <div className={classes.muteContainer} onClick={toggleMuteMic}>
                <img
                  src={UnmutedGreenIcon}
                  className={classes.muteIcon}
                  alt=""
                />
                <span>Unmuted</span>
              </div>
            }
          >
            <div
              className={cl(classes.muteContainer, classes.muted)}
              onClick={toggleMuteMic}
            >
              <img src={MutedIcon} className={classes.muteIcon} alt="" />
              <span>Muted</span>
            </div>
          </Conditional>
        </Grid>
        <Grid item>
          <Conditional cond={host}>
            <Conditional
              cond={allMuted}
              onFalse={
                <div className={classes.muteContainer} onClick={handleMuteAll}>
                  <img
                    src={UnmutedBlackIcon}
                    className={classes.muteIcon}
                    alt=""
                  />
                  <span>Mute all</span>
                </div>
              }
            >
              <div className={classes.allMuted}>
                <img src={MutedIcon} className={classes.muteIcon} alt="" />
                <span>Everyone is Muted</span>
              </div>
            </Conditional>
          </Conditional>
        </Grid>
      </Grid>
      <Drawer
        anchor={"right"}
        open={open}
        variant="persistent"
        classes={{ paper: drawerClasses.paper }}
      >
        <DrawerHeader title={"Video"} />
        <div
          id="jitsi-video-container"
          className={
            !host && sharing
              ? classes.screenShareVideoElement
              : classes.videoElement
          }
          ref={videoContainerRef}
        />
      </Drawer>
    </Conditional>
  );
};

export default Video;
