import React, {useCallback, useLayoutEffect, useState} from "react";
import {CircularProgress, Grid} from "@material-ui/core";
import {QuizContainer, useQuiz} from "Commons/Quiz";
import {Conditional, Text} from "Commons";
import {withCircleStyles} from "Commons/Quiz/styles";
import useStyles from "./styles";
import {StepComponentType} from "Commons/Hooks/useStep";

const StyledCircularProgress = withCircleStyles(CircularProgress)

const WaitNextQuestion: StepComponentType = ({goToLast}) => {

    const classes = useStyles()
    const [playable, setPlayable] = useState(true)
    const {quiz, host, quizSpecs} = useQuiz()
    const quizActions = quiz.getActions()
    const quizPolicies = quiz.getPolicies()
    const instanceId = quizActions.getInstanceId()

    const handleNexQuestion = useCallback(({questionId}) => {
        goToLast(questionId)
    }, [goToLast])

    useLayoutEffect(() => {
        if (host) {
            const storedQuiz = quizPolicies.getStoredQuiz(instanceId)
            if (storedQuiz && quizSpecs.playable(storedQuiz)) {
                goToLast(storedQuiz.questionId)
                quizActions.continueNextQuestion(storedQuiz.questionId).then(void 0)
            } else {
                setPlayable(false)
            }

            return () => {
            }
        }

        quizActions.onNextQuestion(handleNexQuestion)

        return () => {
            quizActions.offNextQuestion(handleNexQuestion)
        }
    }, [quizActions, quizPolicies, quizSpecs, instanceId, goToLast, handleNexQuestion, host])

    return (
        <QuizContainer justify={"center"} direction={"column"}>
            <Conditional cond={playable} onFalse={
                <Grid item className={classes.text}>
                    <Text type={"info"}>
                        Sorry, The quiz is not playable. It has been passed too long since your last visit!
                        Please try again to start a new one.
                    </Text>
                </Grid>
            }>
                <Grid item>
                    <StyledCircularProgress size={200} thickness={1}/>
                </Grid>
                <Grid item className={classes.text}>
                    <Text type={"info"}>
                        The game has already started.
                        Please wait for the next question
                    </Text>
                </Grid>
            </Conditional>
        </QuizContainer>
    )
}

export default WaitNextQuestion