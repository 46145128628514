class VideoSpecs {
    async granted() {
        let granted = false
        try {
            const devices = await navigator.mediaDevices.enumerateDevices()
            devices.forEach(device => {
                if (device.kind === "videoinput" && device.label) {
                    granted = true
                }
            })
        } catch {
        }
        return granted
    }
}

export default VideoSpecs