import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        width : "100%",
        height : "100vh",
        position: "fixed"
    },
    centered : {
        position: "absolute",
        top : "50%",
        left : "50%",
        transform: `translate(-50% , -50%)`,
        zIndex : 1,
        [theme.breakpoints.down('xs')]: {
            width: "90%",
        },
    }
}))

export default useStyles