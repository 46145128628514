import {Meeting} from "Core/Meeting/types";
import * as types from "./types"
import {Action} from "./actions";

const initialState: Meeting = {
    id: "",
    hostUserId: "",
    code: "",
    name: "",
    presentHost: {
        userId: "",
        email: "",
    },
    presentParticipants: [],
    assignedGames : [],
    error: {
        title: "",
        body: "",
        active: false
    }

};

const meetingReducer = (state = initialState, action: Action) => {
    switch (action.type) {
        case types.SET_MEETING:
            return {
                ...state,
                ...action.payload
            }
        case types.SET_PRESENT_PARTICIPANTS:
            return {
                ...state,
                presentParticipants: [
                    ...state.presentParticipants.filter(participant => {
                        return participant.userId !== action.payload.userId
                    }),
                    {...action.payload}
                ],
            }
        case types.REMOVE_MEETING_PARTICIPANT:
            return {
                ...state,
                presentParticipants: [
                    ...state.presentParticipants.filter(participant => {
                        return participant.userId !== action.payload.userId
                    }),
                ],
            }
        case types.SET_PRESENT_HOST_ID:
            return {
                ...state,
                presentHost : {
                    ...state.presentHost,
                    userId : action.payload
                }
            }
        case types.SET_ERROR:
            return {
                ...state,
                error: { ...action.payload, active: true }
            }
        case types.CLEAR_ERROR:
            return {
                ...state,
                error: {
                    title: '',
                    body: '',
                    active: false
                }
            }
        case types.RESET:
            return {
                ...state,
                ...initialState
            }
        default:
            return state;
    }
};

export default meetingReducer;
