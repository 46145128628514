import React from 'react';
import {Typography} from '@material-ui/core';
import useStyles from "./styles";
import cl from "classnames";
import {Color, Weight} from "./types";

type TextProps = {
    className?: string,
    color?: Color
    weight?: Weight
    uppercase ?: boolean
    type?: "info" | "normal" | "sm" | "xs" | "big" | "vwsm" | "vwmd"
}

const typeMap = {
    info: 18,
    big: 28,
    normal: 14,
    sm: 12,
    xs: 10,
    vwsm: 'calc(14px + 0.25vw)',
    vwmd: 'calc(12px + 0.55vw)'
}

const colorMap = {
    dark: "#0D0D0F",
    light: "white",
    gray: "gray",
    grey: "#8B8B97"
}

const Text: React.FC<TextProps> = (props) => {

    const {children, className, type = "normal", color, weight = "normal", uppercase} = props

    const classes = useStyles()

    return (
        <Typography
            component="span"
            style={{
                fontSize: typeMap[type],
                fontWeight: weight,
                textTransform : uppercase ? "uppercase" : "initial",
                ...(color ? {color : colorMap[color]} : {})
            }}
            className={cl(classes.root, className)}
        >
            {children}
        </Typography>
    );
};

export default Text;