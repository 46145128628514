import mobile from "is-mobile";

export const isMobile = () => {
  return mobile({ tablet: true, featureDetect: true });
};

type OnOrientationCB = (event: DeviceOrientationEvent) => void;

export const onOrientation = (cb: OnOrientationCB) => {
  window.addEventListener("deviceorientation", cb, true);
  return () => window.removeEventListener("deviceorientation", cb, true);
};

export const orientationPermissionGranted = async () => {
  // tries to use request permission to grab permission from iOS device
  // if this isnt present (non iOS) it will detect if the 
  try {
    const state = await DeviceOrientationEvent.requestPermission();
    return state === "granted";
  } catch {
    if (ios()) return false;
    return DeviceOrientationEvent ? true : false;
  }
};

export const ios = () => {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
};

export const requestDeviceOrientationPermission = () => {
  console.log("requesting device orientation");
  return new Promise((resolve, reject) => {
    if (!DeviceOrientationEvent || !DeviceOrientationEvent.requestPermission) {
      console.log("device orientation denied");
      return reject("Device orientation denied, events do note exist");
    }

    DeviceOrientationEvent.requestPermission()
      .then((response) => {
        console.log("device orientation granted");
        resolve(response);
      })
      .catch(reject);
  });
};
