import {BackendLeaderboard, Leaderboard, LeaderboardRequest} from "./types";
import {toFrontend} from "./mappers";
import {HttpClient} from "Commons/AuthProvider/types";

class LeaderboardRepository {
    constructor(private httpClient: HttpClient, private baseRoute: string) {
        this.createGameInstanceLeaderBoard = this.createGameInstanceLeaderBoard.bind(this)
        this.getGameInstanceLeaderboard = this.getGameInstanceLeaderboard.bind(this)
    }

    async createOrGet(gameInstanceId: string) {
        let leaderboard: Leaderboard

        try {
            leaderboard = await this.createGameInstanceLeaderBoard(gameInstanceId)
        } catch {
            leaderboard = await this.getGameInstanceLeaderboard(gameInstanceId)
        }

        return leaderboard
    }

    async createGameInstanceLeaderBoard(gameInstanceId: string): Promise<Leaderboard> {
        const leaderboard = await this.httpClient.post<LeaderboardRequest, BackendLeaderboard>(this.baseRoute, {
            name: "Test Leaderboard Name",
            gameInstanceId
        })
        return {
            ...leaderboard,
            players: toFrontend(leaderboard.players)
        }
    }

    async getGameInstanceLeaderboard(instanceId: string): Promise<Leaderboard> {
        const leaderboard = await this.httpClient.get<any, BackendLeaderboard>(`${this.baseRoute}/game/${instanceId}`)
        return {
            ...leaderboard,
            players: toFrontend(leaderboard.players)
        }
    }

}

export default LeaderboardRepository