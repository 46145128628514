import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    videoElement : {
        marginBottom: theme.spacing(2),
        height: "calc(100vh - 80px)", 
        overflow: "hidden",
    },
    screenShareVideoElement : {
        marginBottom: theme.spacing(2),
        width: "94vw",
        height: "calc(100vh - 80px)", 
        overflow: "hidden",
        [theme.breakpoints.down('lg')]: {
            width: "92vw",
        },
        [theme.breakpoints.down('sm')]: {
            width: "80vw",
        },
    },
    videoIconContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: "50%",
        width: '46px',
        height: '46px',
    },
    granted : {
        position : "absolute",
        width : 12,
        height : 12,
        right : 2,
        top : 0,
        borderRadius : "50%",
        background : theme.colors.success
    },
    shareFullModal: {
        boxShadow: "0 2px 20px 0 rgba(0,0,0,0.42)",
        position: "relative",
        padding: theme.spacing(4),
        width: 'auto',
        textAlign: "center",
    },
    shareFullModalImg: {
        margin: "auto",
        transform: "rotate(90deg)"
    },
    shareFullModalTitle : {
        marginTop: theme.spacing(4),
        fontSize: 20,
    },
    passiveIcon: {
        width: 32,
        height: 32,
        color: "#1B1B1B",
    },
    activeIcon: {
        width: 32,
        height: 32,
        color: "white"
    },
    muteRoot: {
        zIndex: 99,
        position: "fixed",
        left: theme.spacing(6),
        bottom: theme.spacing(4),
        maxWidth: "360px",
        userSelect: "none",
        [theme.breakpoints.down("xs")]: {
          left: theme.spacing(2),
          bottom: theme.spacing(8),
        },
      },
      muteContainer: {
        boxSizing: "border-box",
        backgroundColor: theme.backgroundColors.light,
        padding: "13px 24px",
        color: "#0D0D0F",
        border: `2px solid #47C683`,
        borderRadius: "28px",
        fontSize: "19px",
        letterSpacing: "0.24px",
        lineHeight: "16px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "0 0 10px 0 rgba(3,3,3,0.37)",
        cursor: "pointer",
        marginRight: "24px",
        transition: "background-color 0.2s linear",
      },
      muted: {
        backgroundColor: "#DA3B50",
        color: "#FFFFFF",
        border: "2px solid #ECECF3",
        transition: "background-color 0.2s linear",
      },
      allMuted: {
        boxSizing: "border-box",
        backgroundColor: "#0000007A",
        padding: "13px 24px",
        color: "#FFFFFF",
        fontSize: "19px",
        letterSpacing: "0.24px",
        lineHeight: "16px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "12px",
        boxShadow: "0 0 10px 0 rgba(3,3,3,0.37)",
      },
      muteIcon: {
        width: "auto",
        height: "25px",
        marginRight: "12px",
      },
}))

export default useStyles