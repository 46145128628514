import { combineReducers } from 'redux';
import { State } from './types';
import meetingReducer from "./meeting"
import leaderboardReducer from "./leaderboard";
import playerBalanceReducer from "./playerBalance";
import huddleReducer from "./huddle";

export const reducers = combineReducers<State>({
    meeting: meetingReducer,
    leaderboard : leaderboardReducer,
    playerBalance : playerBalanceReducer,
    huddle : huddleReducer
});
